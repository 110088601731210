import "./Navbar.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Navbar() {
  function logOut() {
    const ans = window.confirm("Are you sure you want to log out?");
    if (ans) {
      cookies.remove("refId");
      cookies.remove("user_name");
      cookies.remove("aadhaar");
      window.location.href = "/";
    }
  }

  return (
    <div className="navbar flex-container-row">
      <div className="navbar-left flex-item">CC</div>
      <div className="navbar-right flex-container-row flex-item">
        <div className="navbar-right-item">{cookies.get("user_name")}</div>
        <img
          src="/images/logout.png"
          alt="LogOut"
          className="navbar-right-item logout-icon"
          onClick={logOut}
        />
      </div>
    </div>
  );
}
