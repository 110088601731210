import { useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./New.css";
const { GoogleGenerativeAI } = require("@google/generative-ai");

export default function New() {
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const fileInputRef = useRef(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(file);

      const genAI = new GoogleGenerativeAI(
        process.env.REACT_APP_GEMINI_API_KEY
      );
      const model = genAI.getGenerativeModel({ model: "gemini-pro-vision" });
      await fetchImageDescription(model, file);
    }
  };

  const fetchImageDescription = async (model, file) => {
    if (!process.env.REACT_APP_GEMINI_API_KEY) {
      console.error("Missing GEMINI_API_KEY environment variable");
      return;
    }

    const imageParts = [];

    const processImage = async (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve, reject) => {
        reader.onload = () =>
          resolve({
            inlineData: {
              data: reader.result.split(",")[1], // Extract base64 data
              mimeType: file.type,
            },
          });
        reader.onerror = reject;
      });
    };

    imageParts.push(await processImage(file));
    const prompt = "Describe the image";
    const result = await model.generateContent([prompt, ...imageParts]);
    const response = await result.response;
    const text = response.text();
    setDescription(text);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="new-post-page">
      <Navbar />
      <div className="flex-container-column">
        <div className="new-post-container flex-container-column">
          <div className="new-post-input-container flex-container-column">
            <div className="new-post-input-label">Title</div>
            <input
              type="text"
              placeholder="Enter Title"
              className="new-post-input"
              onChange={handleTitleChange}
              value={title}
            />
          </div>
          <div className="new-post-input-container flex-container-column">
            <div className="new-post-input-label">Location</div>
            <input
              type="text"
              placeholder="Enter Location"
              className="new-post-input"
              onChange={handleLocationChange}
              value={location}
            />
          </div>
          <div
            onClick={handleImageClick}
            className="new-post-image-upload-container flex-container-row"
          >
            <img
              src="/images/file-add.png"
              alt="Upload"
              className="new-post-image-upload-icon"
            />
            <div className="new-post-input-label">Click to upload an image</div>
            <input
              type="file"
              className="new-post-image-upload"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
          {image !== "" ? (
            <img className="new-post-image" src={image} alt="Uploaded" />
          ) : null}
          <div className="new-post-input-container flex-container-column">
            <div className="new-post-input-label">Description</div>
            <input
              type="text"
              placeholder="Enter Description"
              className="new-post-input"
              onChange={handleDescriptionChange}
              value={description}
            />
          </div>
        </div>
        <div className="new-post-buttons-container flex-container-row">
          <button className="footer-button flex-item">SUBMIT</button>
          <div className="footer-text flex-item">CANCEL</div>
        </div>
      </div>
    </div>
  );
}
