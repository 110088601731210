import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-container flex-container-row">
      <button className="footer-button flex-item">NEW POST</button>
      <div className="footer-text flex-item">SURVEYS</div>
    </div>
  );
}
