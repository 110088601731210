import instance from "./init";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const sendOtp = async (aadhaar_number) => {
  try {
    const response = await instance.post("/auth/otp", { aadhaar_number });
    console.log("res.data", response.data);
    console.log("res.data.data.ref_id", response.data.data.ref_id);
    if (response.data.data.ref_id) {
      cookies.set("refId", response.data.data.ref_id, { path: "/" });
    }
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyOtp = async (otp) => {
  try {
    const refId = cookies.get("refId");
    const response = await instance.post("/auth/verify", { refId, otp });
    console.log(response.data.data);
    cookies.set("user_name", response.data.data.name, { path: "/" });
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
};
