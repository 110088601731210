import "./Post.css";

export default function Post(props) {
  return (
    <div className="post-box flex-container-column">
      <div className="post-header flex-container-column">
        <div className="post-title flex-item">{props.post.title}</div>
        <div className="post-location flex-item">
          Location: {props.post.location}
        </div>
        <div className="post-description flex-item">
          {props.post.description}
        </div>
      </div>
      <img
        src={props.post.image}
        alt="Uploaded"
        className="post-image flex-item"
      />
      <div className="post-footer flex-container-row">
        <div className="post-footer-item-container flex-container-row flex-item">
          <img
            className="post-footer-item-image flex-item"
            src="/images/upvote.png"
            alt="Upvote"
          />
          <div className="post-footer-item-text flex-item">
            {props.post.upvotes}
          </div>
        </div>
        <div className="post-footer-item-container flex-container-row flex-item">
          <img
            className="post-footer-item-image flex-item"
            src="/images/downvote.png"
            alt="Downvote"
          />
          <div className="post-footer-item-text flex-item">
            {props.post.downvotes}
          </div>
        </div>
        <div className="post-footer-item-container flex-container-row flex-item">
          <img
            className="post-footer-item-image flex-item"
            src="/images/report.png"
            alt="Report"
          />
          <div className="post-footer-item-text flex-item">Report</div>
        </div>
      </div>
    </div>
  );
}
