import { useEffect } from "react";
import "./Dashboard.css";
import Cookies from "universal-cookie";
import Navbar from "../../components/Navbar/Navbar";
import Post from "../../components/Posts/Post";
import Footer from "../../components/Footer/Footer";
const cookies = new Cookies();

export default function Dashboard() {
  useEffect(() => {
    if (
      cookies.get("aadhaar") === undefined ||
      cookies.get("user_name") === undefined
    ) {
      window.location.href = "/";
    }
  }, []);

  const posts = [
    {
      title: "Post 1",
      location: "This is the content of post 1",
      description: "This is the content of post 1",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 2",
      location: "This is the content of post 2",
      description: "This is the content of post 2",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 3",
      location: "This is the content of post 3",
      description: "This is the content of post 3",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 4",
      location: "This is the content of post 4",
      description: "This is the content of post 4",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 5",
      location: "This is the content of post 5",
      description: "This is the content of post 5",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 6",
      location: "This is the content of post 6",
      description: "This is the content of post 6",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 7",
      location: "This is the content of post 7",
      description: "This is the content of post 7",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 8",
      location: "This is the content of post 8",
      description: "This is the content of post 8",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
    {
      title: "Post 9",
      location: "This is the content of post 9",
      description: "This is the content of post 9",
      image: "/images/modi.webp",
      upvotes: 420,
      downvotes: 69,
    },
  ];

  return (
    <div className="dashboard">
      <Navbar />
      <div className="posts-container flex-container-row">
        {posts.map((post, index) => (
          <Post post={post} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
}
